// @flow
import * as React from "react"
import styled from "styled-components"
import Prism from "prismjs"
import type { ComponentType } from "react"

const Wrapper: ComponentType<{}> = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  margin: 0.5em 0;
`

type Props = {
  children?: React.Node
}

const Snippet = ({ children }: Props) => {
  React.useEffect(() => {
    Prism.highlightAll()
  }, [children])

  return (
    <Wrapper>
      <pre>
        <code className="language-javascript">{children}</code>
      </pre>
    </Wrapper>
  )
}

export default Snippet
