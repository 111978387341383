// @flow
import React from "react"
import Snippet from "./Snippet"
import TextWithReferences from "./TextWithReferences"
import { Divider } from "../shared"

const EXAMPLES = [
  `const { addRules, ReactionRule } = ionian

const myReactionRule = new ReactionRule()
myReactionRule
  .replace([X, Y]
  .by(...)
  .if(...)

addRules(myReactionRule)`,
  `const { addRules, ReactionRule } = ionian

const myReactionRule = new ReactionRule()
myReactionRule
  .replace([Oscillator, Note]
  .by(...)
  .if(...)

addRules(myReactionRule)`,
  `const { addRules, ReactionRule, Oscillator, Note } = ionian

// Remove the Note molecule when an Oscillator and a Note molecule collide
const removeOneMolecule = new ReactionRule()
removeOneMolecule
  .replace([Oscillator, Note])
  .by([Oscillator])
  .if(() => true)

// Remove no molecules when an Oscillator and a Note molecule collide
const removeNoMolecules = new ReactionRule()
removeNoMolecules
  .replace([Oscillator, Note])
  .by([Oscillator, Note])
  .if(() => true)

// Remove both molecules when an Oscillator and a Note molecule collide
const removeBothMolecules = new ReactionRule()
removeBothMolecules
  .replace([Oscillator, Note])
  .by([])
  .if(() => true)

// Apply the Note molecule to the Oscillator molecule
const reducerRule = new ReactionRule()
reducerRule
  .replace([Oscillator, Note])
  .by((first, second) => {
    const [newOscillator, note] = reduceOscillatorWithNote(first, second)
    return [newOscillator, note]
  })
  .if(() => true)

addRules(removeOneMolecule, removeNoMolecules, removeBothMolecules, reducerRule)`,
  `const { addRules, ReactionRule } = ionian

const myReactionRule = new ReactionRule()
myReactionRule
  .replace([Oscillator]
  .by(...)
  .if(...)

addRules(myReactionRule)`,
  `const { addRules, ReactionRule, Oscillator, Note } = ionian

const simpleRule = new ReactionRule()
simpleRule
  .replace([Oscillator, Note])
  .by([Oscillator])
  .if(() => true)

addRules(simpleRule)`
]

const ReactionRules = () => (
  <>
    <TextWithReferences>
      At the heart of Ionian is the reaction rule. Reaction rules allow us to
      specify the behavior of the action that should take place when two
      molecules collide. They are written in this manner:
    </TextWithReferences>
    <Snippet>{EXAMPLES[0]}</Snippet>
    <TextWithReferences>
      We always need to provide two molecules that we want to base our reaction
      rule on. For instance, we could write
    </TextWithReferences>
    <Snippet>{EXAMPLES[1]}</Snippet>
    <TextWithReferences>
      to create a reaction rule that runs when an Oscillator and a Note molecule
      collide. Next, we specify what we should replace the two molecules with.
      This can either be no molecules, a single molecule, two molecules, or we
      can provide a function that runs a reducer to compute the result as in
      this example.
    </TextWithReferences>
    <Snippet>{EXAMPLES[2]}</Snippet>
    <TextWithReferences>
      After specifying what we want to replace the molecules with, we pass a
      condition function that must evaluate to true if a reaction rule should
      run. Finally, we add our rules using the addRules function. Utility
      functions are available which enables various functions to be performed on
      different molecules. More complex rules can, of course, be written, and a
      solution can also be composed of multiple rules.
    </TextWithReferences>
    <Divider />
  </>
)

export default ReactionRules
