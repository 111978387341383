// @flow
import { uuidv4 } from "../../helpers"

export const COLOR = "#DDD8B8"
const RADIUS = 28

export type Clean = {|
  +type: "clean",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {}
|}

export type JSON = $ReadOnly<{| type: "clean", id: string, data: {} |}>

export const createClean = () => {
  return {
    type: "clean",
    id: uuidv4(),
    color: COLOR,
    text: "🗑️",
    radius: RADIUS,
    data: {}
  }
}

export const toJSON: Clean => JSON = clean => {
  const { type, id, data } = clean
  return { type, id, data }
}
