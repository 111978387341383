// @flow
import { uuidv4, randomInRange } from "../helpers"
import { toJSON as octaveToJSON, DEFAULT_OCTAVE } from "./octave"
import type { Octave, JSON as OctaveJSON } from "./octave"

export const COLOR = "#4363D8"
const RADIUS = 25

export const NOTES = ["A", "A#", "B", "C", "C#", "D", "D#", "E", "F", "G", "G#"]

const randomNote = () => {
  const note = NOTES[randomInRange(0, NOTES.length - 1)]
  return `${note}`
}

export type NoteParams = {| id: string, note: string, octave: Octave |}

export type Note = {|
  +type: "note",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    note: string,
    octave: Octave
  }
|}

export type JSON = $ReadOnly<{|
  type: "note",
  id: string,
  data: {
    note: string,
    octave: OctaveJSON
  }
|}>

export const DEFAULT_NOTE: Note = {
  type: "note",
  id: uuidv4(),
  color: COLOR,
  text: "C",
  radius: RADIUS,
  data: {
    note: "C",
    octave: DEFAULT_OCTAVE
  }
}

export const createNote = (
  { id, note, octave }: NoteParams = {
    id: uuidv4(),
    note: randomNote(),
    octave: DEFAULT_OCTAVE
  }
) => {
  return {
    type: "note",
    id,
    color: COLOR,
    text: note,
    radius: RADIUS,
    data: {
      note,
      octave
    }
  }
}

export const toJSON: Note => JSON = molecule => {
  const {
    type,
    id,
    data: { note, octave }
  } = molecule

  return {
    type,
    id,
    data: {
      note,
      octave: octaveToJSON(octave)
    }
  }
}
