// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Subheader: ComponentType<{}> = styled.h3`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  padding: 0;
  margin: 0.2em 0;
`

export default Subheader
