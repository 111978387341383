// @flow
import { uuidv4 } from "../../helpers"

export const COLOR = "#F58231"
const RADIUS = 30

export type Play = {|
  +type: "play",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {}
|}

export type JSON = $ReadOnly<{| type: "play", id: string, data: {} |}>

export const createPlay = () => {
  return {
    type: "play",
    id: uuidv4(),
    color: COLOR,
    text: "▶️",
    radius: RADIUS,
    data: {}
  }
}

export const toJSON: Play => JSON = play => {
  const { type, id, data } = play
  return { type, id, data }
}
