// @flow
import { uuidv4 } from "../../helpers"

export const COLOR = "#3CB44B"
const PITCH_SHIFT_TEXT = "🧬"
const RADIUS = 25

export type PitchShift = {|
  +type: "effect",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    effectType: "pitchShift"
  }
|}

export const createPitchShift = () => {
  return {
    type: "effect",
    id: uuidv4(),
    color: COLOR,
    text: PITCH_SHIFT_TEXT,
    radius: RADIUS,
    data: {
      effectType: "pitchShift"
    }
  }
}
