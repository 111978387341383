// @flow
import { randomInRange } from "../helpers"
import {
  createOscillator,
  COLOR as OSCILLATOR_COLOR,
  toJSON as oscillatorToJSON
} from "./audioSources/oscillator"
import { createNote, COLOR as NOTE_COLOR, toJSON as noteToJSON } from "./note"
import {
  createChord,
  createRandomChord,
  COLOR as CHORD_COLOR,
  toJSON as chordToJSON
} from "./chord"
import {
  createSynth,
  COLOR as SYNTH_COLOR,
  toJSON as synthToJSON
} from "./audioSources/synth"
import {
  createMonoSynth,
  COLOR as MONO_SYNTH_COLOR,
  toJSON as monoSynthToJSON
} from "./audioSources/monoSynth"
import {
  createPlay,
  COLOR as PLAY_COLOR,
  toJSON as playToJSON
} from "./functions/play"
import {
  createClean,
  COLOR as CLEAN_COLOR,
  toJSON as cleanToJSON
} from "./functions/clean"

import {
  createOctave,
  createRandomOctave,
  COLOR as OCTAVE_COLOR,
  toJSON as octaveToJSON
} from "./octave"
import {
  createEffect,
  createChorus,
  CHORUS_COLOR,
  createReverb,
  REVERB_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  createPitchShift,
  PITCH_SHIFT_COLOR,
  toJSON as effectToJSON
} from "./effects"
import { effects } from "./effects"

import type {
  Oscillator,
  JSON as OscillatorJSON
} from "./audioSources/oscillator"
import type { Synth, JSON as SynthJSON } from "./audioSources/synth"
import type { MonoSynth, JSON as MonoSynthJSON } from "./audioSources/monoSynth"
import type { Note, JSON as NoteJSON } from "./note"
import type { Chord, JSON as ChordJSON } from "./chord"
import type { Play, JSON as PlayJSON } from "./functions/play"
import type { Clean, JSON as CleanJSON } from "./functions/clean"
import type { Effect, JSON as EffectJSON } from "./effects"
import type { Octave, JSON as OctaveJSON } from "./octave"

export type { Oscillator } from "./audioSources/oscillator"
export type { Synth } from "./audioSources/synth"
export type { MonoSynth } from "./audioSources/monoSynth"
export type { Note } from "./note"
export type { Chord } from "./chord"
export type { Play } from "./functions/play"
export type { Clean } from "./functions/clean"
export type { Octave } from "./octave"
export type { Effect, EffectString } from "./effects"

export type Waveform = "sine" | "square" | "triangle" | "sawtooth"

export type Molecule =
  | Oscillator
  | Note
  | Effect
  | Play
  | Chord
  | Synth
  | MonoSynth
  | Clean
  | Octave

export type MoleculeString =
  | "chord"
  | "chorus"
  | "clean"
  | "effect"
  | "note"
  | "octave"
  | "oscillator"
  | "pingPongDelay"
  | "pitchShift"
  | "play"
  | "reverb"
  | "synth"
  | "monoSynth"

export type MoleculeJSON =
  | EffectJSON
  | OscillatorJSON
  | NoteJSON
  | ChordJSON
  | PlayJSON
  | CleanJSON
  | SynthJSON
  | MonoSynthJSON
  | OctaveJSON

const initializers = [
  { type: "oscillator", callback: createOscillator },
  { type: "note", callback: createNote },
  { type: "chorus", callback: createChorus },
  { type: "reverb", callback: createReverb },
  { type: "pingPongDelay", callback: createPingPongDelay },
  { type: "play", callback: createPlay },
  { type: "chord", callback: createChord },
  { type: "synth", callback: createSynth },
  { type: "monoSynth", callback: createMonoSynth },
  { type: "clean", callback: createClean },
  { type: "octave", callback: createOctave },
  { type: "pitchShift", callback: createPitchShift }
]

const createMolecule: () => Molecule = () => {
  const randomMolecule = initializers[randomInRange(0, initializers.length - 1)]
  const molecules = [
    ...effects,
    "oscillator",
    "effect",
    "play",
    "note",
    "chord",
    "clean",
    "synth",
    "octave",
    "monoSynth"
  ]

  if (molecules.includes(randomMolecule.type)) return randomMolecule.callback()
  throw new Error(`Unhandled molecule type: ${randomMolecule.type}`)
}

const toJSON: Molecule => MoleculeJSON = molecule => {
  if (molecule.type === "oscillator") {
    return oscillatorToJSON(molecule)
  }

  if (molecule.type === "note") {
    return noteToJSON(molecule)
  }

  if (molecule.type === "play") {
    return playToJSON(molecule)
  }

  if (molecule.type === "effect") {
    return effectToJSON(molecule)
  }

  if (molecule.type === "synth") {
    return synthToJSON(molecule)
  }

  if (molecule.type === "chord") {
    return chordToJSON(molecule)
  }

  if (molecule.type === "clean") {
    return cleanToJSON(molecule)
  }

  if (molecule.type === "octave") {
    return octaveToJSON(molecule)
  }

  if (molecule.type === "monoSynth") {
    return monoSynthToJSON(molecule)
  }

  throw new Error(`Unhandled molecule type: ${molecule.type}`)
}

export const toString: (Molecule[]) => string = molecules => {
  return JSON.stringify(
    {
      count: molecules.length,
      molecules: molecules.map(toJSON)
    },
    null,
    2
  )
}

export {
  createEffect,
  createChorus,
  CHORUS_COLOR,
  createNote,
  NOTE_COLOR,
  createMolecule,
  createOscillator,
  OSCILLATOR_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  createPlay,
  PLAY_COLOR,
  createReverb,
  REVERB_COLOR,
  createChord,
  createRandomChord,
  CHORD_COLOR,
  createSynth,
  SYNTH_COLOR,
  createMonoSynth,
  MONO_SYNTH_COLOR,
  createClean,
  CLEAN_COLOR,
  createOctave,
  createRandomOctave,
  OCTAVE_COLOR,
  createPitchShift,
  PITCH_SHIFT_COLOR,
  effects
}
