// @flow
import * as React from "react"
import styled from "styled-components"
import Code from "./Code"
import Row from "./Row"
import type { ComponentType } from "react"

type Props = {
  headers: string[],
  data: { name: string, type: React.Node }[]
}

const Wrapper: ComponentType<{}> = styled.div`
  margin: 1em 0;
`

const TableHeader: ComponentType<{}> = styled(Row)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.darkGray};
  font-weight: bold;
`

const TableRow: ComponentType<{}> = styled(Row)`
  border-right: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-left: 1px solid ${({ theme }) => theme.colors.darkGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};

  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.lightGray};
  }
`

const TableColumn: ComponentType<{}> = styled.div`
  display: block;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  border-right: 1px solid ${({ theme }) => theme.colors.darkGray};
  padding: 0.5em;
`

const Table = (props: Props) => {
  const { headers, data } = props

  return (
    <Wrapper>
      <TableHeader>
        {headers.map((header, index) => (
          <TableColumn key={index}>{header}</TableColumn>
        ))}
      </TableHeader>
      {data.map((datum, index) => (
        <TableRow key={index}>
          <TableColumn>
            <Code>{datum.name}</Code>
          </TableColumn>
          <TableColumn>{datum.type}</TableColumn>
        </TableRow>
      ))}
    </Wrapper>
  )
}

export default Table
