// @flow
import React from "react"
import styled from "styled-components"
import Example from "./Example"
import Snippet from "./Snippet"
import TextWithReferences from "./TextWithReferences"
import { parseTypes } from "./parseTypes"
import { Subheader, Code, Divider, Table } from "../shared"
import { slugify } from "../../helpers"
import docs from "./docs"

const Bold = styled.b`
  display: block;
  margin: 0.5em 0;
`

const Reducers = () => (
  <>
    <TextWithReferences>
      Reducers follow a simple pattern where they always return a tuple
      containing a new molecule and an unmodified molecule. For instance,
      reduceSynthWithChord returns a new Synth and the original Chord provided
      to the function. By convention, they are written as such:
    </TextWithReferences>
    <Snippet>
      const [newFirstMolecule, originalSecondMolecule] = reduceXWithY(first,
      second)
    </Snippet>
    <TextWithReferences>
      Reducers exist for most of the molecule types. Below is a list of all
      available reducers.
    </TextWithReferences>
    <Divider />
    {docs.reducers
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        return 0
      })
      .map((reducer, index) => (
        <div key={index}>
          <Subheader id={slugify(reducer.name)}>
            <Code>{reducer.name}</Code>
          </Subheader>
          <Bold>Input:</Bold>
          <Table headers={["Name", "Type"]} data={parseTypes(reducer.input)} />
          <Bold>Returns:</Bold>
          {parseTypes(reducer.returns).map((type, index) => (
            <React.Fragment key={index}>{type.type}</React.Fragment>
          ))}
          <TextWithReferences>{reducer.description}</TextWithReferences>
          <Example>
            <Snippet>{reducer.example}</Snippet>
          </Example>
          <Divider />
        </div>
      ))}
  </>
)

export default Reducers
