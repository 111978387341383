// @flow
export const HEIGHT_DIVIDER = 2.25
export const DEFAULT_FPS = 10
export const DEFAULT_MASS = 0.1
export const DEFAULT_RADIUS = 25
export const DEFAULT_ATTACK = 0.005
export const DEFAULT_DECAY = 0.1
export const DEFAULT_SUSTAIN = 0.3
export const DEFAULT_RELEASE = 1
export const DEFAULT_WAVEFORM = "sine"
export const SPEED = 20
export const NODES = 0
export const INITIAL_RULES = {
  chord: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  chorus: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  clean: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  effect: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  monoSynth: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  note: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  octave: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  oscillator: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  pingPongDelay: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  pitchShift: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  play: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  synth: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  },
  reverb: {
    chord: [],
    chorus: [],
    clean: [],
    effect: [],
    monoSynth: [],
    note: [],
    octave: [],
    oscillator: [],
    pingPongDelay: [],
    pitchShift: [],
    play: [],
    reverb: [],
    synth: []
  }
}
