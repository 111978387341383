// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Code: ComponentType<{}> = styled.span`
  background-color: ${({ theme }) => theme.colors.lightGray};
  font-family: ${({ theme }) => theme.monospace.fontFamily};
  color: ${({ theme }) => theme.colors.black};
  padding: 0.2em;
  border-radius: 3px;
  text-decoration: none;
`

export default Code
