// @flow
import type {
  Effect,
  Oscillator,
  Synth,
  MonoSynth,
  Chord,
  Note as NoteMolecule,
  Octave
} from "../molecules"
import {
  isChordInKey as checkChordInKey,
  chordFromNote,
  scaleFromKey,
  MINOR_CHORD,
  MAJOR_SCALE,
  MINOR_SCALE
} from "../musicTheory"
import type { Note } from "../musicTheory"

type ConsoleData = { type: "error" | "message", message: string }[]

export const effectChainLength: (
  audioSource: Oscillator | Synth | MonoSynth
) => number = audioSource => audioSource && audioSource.data.effects.length

export const isEffectInChain: (
  audioSource: ?Oscillator | ?Synth | ?MonoSynth,
  effect: ?Effect
) => boolean = (audioSource, effect) => {
  if (audioSource && effect) {
    const {
      data: { effects }
    } = audioSource
    const {
      data: { effectType }
    } = effect

    return effects.some(effect => effectType)
  }

  return false
}

export const requestJSON = (
  setConsoleData: ((ConsoleData) => ConsoleData) => void
) => {
  return (url: string) => {
    try {
      const request = new XMLHttpRequest()
      request.open("GET", url, false)
      request.send(null)
      return JSON.parse(request.responseText)
    } catch (e) {
      setConsoleData(prevState => [
        ...prevState,
        { type: "error", message: `ERROR: ${e.message}` }
      ])
      return null
    }
  }
}

const isChordInKey = (chord: Chord, note: Note, key: "major" | "minor") => {
  const { notes } = chord.data
  return checkChordInKey(notes, note, key)
}

const isNoteInScale = (
  molecule: NoteMolecule,
  key: Note,
  scale: "major" | "minor"
) => {
  const {
    data: { note }
  } = molecule

  if (scale === "major") {
    return scaleFromKey(MAJOR_SCALE, key).includes(note)
  } else {
    return scaleFromKey(MINOR_SCALE, key).includes(note)
  }
}

const isOctaveInRange = (molecule: Octave, start: number, end: number) => {
  const {
    data: { octave }
  } = molecule

  return octave >= start && octave <= end
}

export {
  isChordInKey,
  isNoteInScale,
  isOctaveInRange,
  chordFromNote,
  MINOR_CHORD
}
