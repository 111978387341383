// @flow
import React from "react"
import Reference from "./Reference"
import { Code } from "../shared"
import docs from "./docs"

const REFERENCES = Object.keys(docs)
  .flatMap(key => docs[key])
  .map(({ name }) => name)

const CodeWithReferences = ({ word }: { word: string }) => {
  if (REFERENCES.includes(word)) {
    return (
      <Code>
        <Reference>{word}</Reference>
      </Code>
    )
  }

  return <Code>{word}</Code>
}

export default CodeWithReferences
