// @flow
import { uuidv4 } from "../helpers"
import { generateRandomChord } from "../musicTheory"
import { DEFAULT_OCTAVE, toJSON as octaveToJSON } from "./octave"
import type { Octave, JSON as OctaveJSON } from "./octave"
import type { Note } from "../musicTheory"

export const COLOR = "#000075"
const DEFAULT_NOTES = ["C", "E", "G"]
const RADIUS = 32

export type ChordParams = {|
  id: string,
  notes: Note[],
  octave: Octave
|}

export type Chord = {|
  +type: "chord",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    notes: Note[],
    octave: Octave
  }
|}

export type JSON = $ReadOnly<{|
  type: "chord",
  id: string,
  data: {
    notes: Note[],
    octave: OctaveJSON
  }
|}>

export const DEFAULT_CHORD: Chord = {
  type: "chord",
  id: uuidv4(),
  color: COLOR,
  text: `${DEFAULT_NOTES.join("")}`,
  radius: RADIUS,
  data: {
    notes: DEFAULT_NOTES,
    octave: DEFAULT_OCTAVE
  }
}

export const createChord = (
  { id, notes, octave }: ChordParams = {
    id: uuidv4(),
    notes: DEFAULT_NOTES,
    octave: DEFAULT_OCTAVE
  }
) => ({
  type: "chord",
  id,
  color: COLOR,
  text: `${notes.join("")}`,
  radius: RADIUS,
  data: {
    notes,
    octave
  }
})

export const createRandomChord = (
  { id, notes, octave }: ChordParams = {
    id: uuidv4(),
    notes: generateRandomChord(),
    octave: DEFAULT_OCTAVE
  }
) => createChord({ id, notes, octave })

export const toJSON: Chord => JSON = chord => {
  const {
    type,
    id,
    data: { notes, octave }
  } = chord

  return {
    type,
    id,
    data: {
      notes,
      octave: octaveToJSON(octave)
    }
  }
}
