// @flow
import { uuidv4, randomInRange } from "../helpers"

export const COLOR = "#911EB4"
const OCTAVE = 4
const RADIUS = 25

const OCTAVES = [...Array(7).keys()].map(octave => octave + 1)

export type OctaveParams = {|
  id: string,
  octave: number
|}

export type Octave = {|
  +type: "octave",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    octave: number
  }
|}

export type JSON = $ReadOnly<{|
  type: "octave",
  id: string,
  data: { octave: number }
|}>

export const DEFAULT_OCTAVE: Octave = {
  type: "octave",
  id: uuidv4(),
  color: COLOR,
  text: `${OCTAVE}`,
  radius: RADIUS,
  data: {
    octave: OCTAVE
  }
}

export const generateRandomOctave = () =>
  OCTAVES[randomInRange(0, OCTAVES.length - 1)]

export const createOctave = (
  { id, octave }: OctaveParams = { id: uuidv4(), octave: OCTAVE }
) => ({
  type: "octave",
  id,
  color: COLOR,
  text: `${octave}`,
  radius: RADIUS,
  data: {
    octave
  }
})

export const createRandomOctave = (
  { id, octave }: OctaveParams = {
    id: uuidv4(),
    octave: generateRandomOctave()
  }
) => createOctave({ id, octave })

export const toJSON: Octave => JSON = octave => {
  const { type, id, data } = octave
  return { type, id, data }
}
