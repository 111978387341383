// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Divider: ComponentType<{}> = styled.hr`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray};
  margin: 1.5em 0;
`

export default Divider
