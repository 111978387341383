// @flow
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import chevronDown from "../../assets/chevronDown.svg"
import chevronLeft from "../../assets/chevronLeft.svg"
import type { ComponentType } from "react"

type Props = {
  header: string,
  data: { title: string, content: string, onClick: () => void }[]
}

const Wrapper: ComponentType<{ isOpen: boolean }> = styled.div`
  position: relative;
  background: url(${({ isOpen }) => (isOpen ? chevronDown : chevronLeft)});
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  margin: 1em 0.25vw;
  width: 100%;
  user-select: none;
`

const Header: ComponentType<{ isOpen: boolean }> = styled.p`
  position: relative;
  padding: 0.5em;
  font-weight: bold;
  margin: 0;
  border: 2px solid
    ${({ theme, isOpen }) => (isOpen ? theme.colors.blue : theme.colors.gray)};
`

const List: ComponentType<{ isOpen: boolean }> = styled.ul`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.gray};
  border-top: none;
  box-shadow: 1px 1px 4px rgba(100, 100, 100, 0.5);
  list-style: none;
`

const ListElement: ComponentType<{}> = styled.li`
  position: relative;
  margin: 0;
  padding: 0.5em;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

  :last-child {
    border-bottom: none;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
    font-weight: bold;
  }
`

const Dropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const wrapperRef: any = useRef(null)
  const { header, data } = props

  const toggleList = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleClickOutside = (e: SyntheticEvent<HTMLDivElement>) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        setIsOpen(false)
      }
    }

    window.addEventListener("click", handleClickOutside, false)

    return () => window.removeEventListener("click", handleClickOutside, false)
  }, [data, isOpen])

  return (
    <Wrapper isOpen={isOpen} ref={wrapperRef}>
      <Header isOpen={isOpen} onClick={toggleList}>
        {header}
      </Header>
      <List isOpen={isOpen}>
        {data.map(({ title, content, onClick }, index) => (
          <ListElement
            key={index}
            onClick={() => {
              toggleList()
              onClick()
            }}
          >
            {title}
          </ListElement>
        ))}
      </List>
    </Wrapper>
  )
}

export default Dropdown
