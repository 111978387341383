// @flow
const AUDIO_SOURCES = [
  { type: "Oscillator" },
  { type: "Synth" },
  { type: "MonoSynth" }
]
const KEYS = [{ type: "major" }, { type: "minor" }]

const EFFECTS = [
  { type: "Chorus" },
  { type: "PingPongDelay" },
  { type: "PitchShift" },
  { type: "Reverb" }
]

const MOLECULES = [
  ...EFFECTS,
  { type: "Oscillator" },
  { type: "Synth" },
  { type: "MonoSynth" },
  { type: "Clean" },
  { type: "Play" },
  { type: "Chord" },
  { type: "Note" },
  { type: "Octave" }
]

const NOTES = [
  { type: "C" },
  { type: "C#" },
  { type: "D" },
  { type: "D#" },
  { type: "E" },
  { type: "F" },
  { type: "F#" },
  { type: "G" },
  { type: "G#" },
  { type: "A" },
  { type: "A#" },
  { type: "B" }
]

const docs = {
  molecules: [
    {
      name: "Oscillator",
      properties: [
        { name: "note", type: "Note" },
        {
          name: "waveform",
          type: "union",
          elements: [
            { type: "sine" },
            { type: "square" },
            { type: "sawtooth" },
            { type: "triangle" }
          ]
        },
        { name: "isActive", type: "boolean" },
        {
          name: "effects",
          type: "array",
          elements: [{ type: "union", elements: EFFECTS }]
        }
      ],
      relatedReducersAndFunctions: [
        "reduceOscillatorWithEffect",
        "reduceOscillatorWithNote",
        "reduceOscillatorWithPlay",
        "effectChainLength",
        "isEffectInChain"
      ]
    },
    {
      name: "Synth",
      properties: [
        { name: "chord", type: "Chord" },
        {
          name: "effects",
          type: "array",
          elements: [{ type: "union", elements: EFFECTS }]
        }
      ],
      relatedReducersAndFunctions: [
        "reduceSynthWithEffect",
        "reduceSynthWithChord",
        "reduceSynthWithPlay",
        "effectChainLength",
        "isEffectInChain"
      ]
    },
    {
      name: "MonoSynth",
      properties: [
        { name: "note", type: "Note" },
        {
          name: "effects",
          type: "array",
          elements: [{ type: "union", elements: EFFECTS }]
        }
      ],
      relatedReducersAndFunctions: [
        "reduceMonoSynthWithEffect",
        "reduceMonoSynthWithNote",
        "reduceMonoSynthWithPlay",
        "effectChainLength",
        "isEffectInChain"
      ]
    },
    {
      name: "Chorus",
      properties: [{ name: "effectType", type: "chorus" }],
      relatedReducersAndFunctions: [
        "reduceSynthWithEffect",
        "reduceOscillatorWithEffect",
        "reduceMonoSynthWithEffect",
        "isEffectInChain"
      ]
    },
    {
      name: "PingPongDelay",
      properties: [{ name: "effectType", type: "pingPongDelay" }],
      relatedReducersAndFunctions: [
        "reduceSynthWithEffect",
        "reduceOscillatorWithEffect",
        "reduceMonoSynthWithEffect",
        "isEffectInChain"
      ]
    },
    {
      name: "PitchShift",
      properties: [{ name: "effectType", type: "pitchShift" }],
      relatedReducersAndFunctions: [
        "reduceSynthWithEffect",
        "reduceOscillatorWithEffect",
        "reduceMonoSynthWithEffect",
        "isEffectInChain"
      ]
    },
    {
      name: "Reverb",
      properties: [{ name: "effectType", type: "reverb" }],
      relatedReducersAndFunctions: [
        "reduceSynthWithEffect",
        "reduceOscillatorWithEffect",
        "reduceMonoSynthWithEffect",
        "isEffectInChain"
      ]
    },
    {
      name: "Clean",
      properties: [],
      relatedReducersAndFunctions: []
    },
    {
      name: "Play",
      properties: [],
      relatedReducersAndFunctions: [
        "reduceSynthWithPlay",
        "reduceOscillatorWithPlay",
        "reduceMonoSynthWithPlay"
      ]
    },
    {
      name: "Chord",
      properties: [
        {
          name: "notes",
          type: "array",
          elements: [{ type: "union", elements: NOTES }]
        },
        {
          name: "octave",
          type: "Octave"
        }
      ],
      relatedReducersAndFunctions: [
        "reduceChordWithOctave",
        "reduceSynthWithChord",
        "isChordInKey"
      ]
    },
    {
      name: "Note",
      properties: [
        { name: "note", type: "union", elements: NOTES },
        { name: "octave", type: "Octave" }
      ],
      relatedReducersAndFunctions: [
        "reduceMonoSynthWithNote",
        "reduceOscillatorWithNote",
        "reduceNoteWithOctave",
        "isNoteInScale"
      ]
    },
    {
      name: "Octave",
      properties: [{ name: "octave", type: "number" }],
      relatedReducersAndFunctions: [
        "reduceNoteWithOctave",
        "reduceChordWithOctave",
        "isOctaveInRange"
      ]
    }
  ],
  reducers: [
    {
      name: "reduceOscillatorWithEffect",
      input: [
        {
          name: "effect",
          type: "union",
          elements: EFFECTS
        },
        {
          name: "oscillator",
          type: "Oscillator"
        },
        {
          name: "effect",
          type: "union",
          elements: EFFECTS
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [
                { type: "Oscillator" },
                {
                  type: "union",
                  elements: EFFECTS
                }
              ]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceOscillatorWithEffect requires an Oscillator and an effect molecule. It creates a new Oscillator with the effect added to the effect chain and returns the new oscillator and the original effect.

If the reducer does not receive an Oscillator and an effect molecule, it returns a tuple of null values.`,
      example: `const {
  addRules,
  ReactionRule,
  Oscillator,
  Reverb,
  reduceOscillatorWithEffect
} = ionian

const oscillatorRule = new ReactionRule()
oscillatorRule
  .replace([Oscillator, Reverb])
  .by((first, second) => {
    const [newOscillator, reverb] = reduceOscillatorWithEffect(
      Reverb,
      first,
      second
    )
    return [newOscillator, reverb]
  })
  .if(() => true)

addRules(oscillatorRule)`
    },
    {
      name: "reduceOscillatorWithNote",
      input: [
        {
          name: "oscillator",
          type: "Oscillator"
        },
        {
          name: "note",
          type: "Note"
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "Oscillator" }, { type: "Note" }]
            },
            { type: "tuple", elements: [{ type: "null" }, { type: "null" }] }
          ]
        }
      ],
      description: `reduceOscillatorWithNote requires an Oscillator and a Note molecule. It creates a new Oscillator where its note property points to the provided Note. It returns the new oscillator and the original note.

If the reducer does not receive an Oscillator and a Note molecule, it returns a tuple of null values.`,
      example: `const {
  addRules,
  ReactionRule,
  Oscillator,
  Note,
  reduceOscillatorWithNote
} = ionian

const oscillatorRule = new ReactionRule()
oscillatorRule
  .replace([Oscillator, Note])
  .by((first, second) => {
    const [newOscillator, note] = reduceOscillatorWithNote(first, second)
    return [newOscillator, note]
  })
  .if(() => true)

addRules(oscillatorRule)`
    },
    {
      name: "reduceOscillatorWithPlay",
      input: [
        {
          name: "oscillator",
          type: "Oscillator"
        },
        {
          name: "play",
          type: "Play"
        },
        {
          name: "options",
          type: "object",
          elements: [
            {
              name: "waveform",
              type: "union",
              elements: [
                { type: "square" },
                { type: "sawtooth" },
                { type: "triangle" },
                { type: "sine" }
              ]
            }
          ]
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "Oscillator" }, { type: "Play" }]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceOscillatorWithPlay requires an Oscillator and a Play molecule. It starts the oscillator and returns a new oscillator (identical to the original) and the original Play molecule.

If the reducer does not receive an Oscillator and a Play molecule, it returns a tuple of null values.`,
      example: `const {
  addRules,
  ReactionRule,
  Oscillator,
  Play,
  reduceOscillatorWithPlay
} = ionian

const oscillatorRule = new ReactionRule()
oscillatorRule
  .replace([Oscillator, Play])
  .by((first, second) => {
    const [newOscillator, play] = reduceOscillatorWithPlay(first, second)
    return [newOscillator, play]
  })
  .if(() => true)

addRules(oscillatorRule)`
    },
    {
      name: "reduceSynthWithChord",
      input: [
        {
          name: "synth",
          type: "Synth"
        },
        {
          name: "chord",
          type: "Chord"
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "Synth" }, { type: "Chord" }]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceSynthWithChord requires a Synth and a Chord molecule. It creates a new Synth where its chord property points to the provided Chord. It returns the new synth and the original chord.

If the reducer does not receive a Synth and a Chord molecule, it returns a tuple of null values.`,
      example: `const { addRules, ReactionRule, Synth, Chord, reduceSynthWithChord } = ionian

const synthRule = new ReactionRule()
synthRule
  .replace([Synth, Chord])
  .by((first, second) => {
    const [newSynth, chord] = reduceSynthWithChord(first, second)
    return [newSynth, chord]
  })
  .if(() => true)

addRules(synthRule)`
    },
    {
      name: "reduceSynthWithPlay",
      input: [
        {
          name: "synth",
          type: "Synth"
        },
        {
          name: "play",
          type: "Play"
        },
        {
          name: "options",
          type: "object",
          elements: [
            {
              name: "waveform",
              type: "union",
              elements: [
                { type: "square" },
                { type: "sawtooth" },
                { type: "triangle" },
                { type: "sine" }
              ]
            },
            { name: "attack", type: "number" },
            { name: "decay", type: "number" },
            { name: "sustain", type: "number" },
            { name: "release", type: "number" }
          ]
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "Synth" }, { type: "Play" }]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceSynthWithPlay requires a Synth and a Play molecule. It triggers the chord of the Synth and returns a new synth (identical to the original) and the original Play molecule.

If the reducer does not receive a Synth and a Play molecule, it returns a tuple of null values.`,
      example: `const { addRules, ReactionRule, Synth, Play, reduceSynthWithPlay } = ionian

const synthRule = new ReactionRule()
synthRule
  .replace([Synth, Play])
  .by((first, second) => {
    const [newSynth, play] = reduceSynthWithPlay(first, second, {
      waveform: "sine",
      attack: 2,
      decay: 0.001,
      sustain: 0.002,
      release: 4
    })
    return [newSynth, play]
  })
  .if(() => true)

addRules(synthRule)`
    },
    {
      name: "reduceSynthWithEffect",
      input: [
        {
          name: "effect",
          type: "union",
          elements: EFFECTS
        },
        {
          name: "synth",
          type: "Synth"
        },
        {
          name: "effect",
          type: "union",
          elements: EFFECTS
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [
                { type: "Synth" },
                {
                  type: "union",
                  elements: EFFECTS
                }
              ]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceSynthWithEffect requires a Synth and an effect molecule. It creates a new Synth with the effect added to the effect chain and returns the new synth and the original effect.

If the reducer does not receive a Synth and an effect molecule, it returns a tuple of null values.`,
      example: `const { addRules, ReactionRule, Synth, Reverb, reduceSynthWithEffect } = ionian

const synthRule = new ReactionRule()
synthRule
  .replace([Synth, Reverb])
  .by((first, second) => {
    const [newSynth, reverb] = reduceSynthWithEffect(Reverb, first, second)
    return [newSynth, reverb]
  })
  .if(() => true)

addRules(synthRule)`
    },
    {
      name: "reduceMonoSynthWithNote",
      input: [
        {
          name: "monoSynth",
          type: "MonoSynth"
        },
        {
          name: "note",
          type: "Note"
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "MonoSynth" }, { type: "Note" }]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceMonoSynthWithNote requires a MonoSynth and a Note molecule. It creates a new MonoSynth where its note property points to the provided Note. It returns the new mono synth and the original note.

If the reducer does not receive a MonoSynth and a Note molecule, it returns a tuple of null values.`,
      example: `const {
  addRules,
  ReactionRule,
  MonoSynth,
  Note,
  reduceMonoSynthWithNote
} = ionian

const monoSynthRule = new ReactionRule()
monoSynthRule
  .replace([MonoSynth, Note])
  .by((first, second) => {
    const [newMonoSynth, note] = reduceMonoSynthWithNote(first, second)
    return [newMonoSynth, note]
  })
  .if(() => true)

addRules(monoSynthRule)`
    },
    {
      name: "reduceMonoSynthWithPlay",
      input: [
        {
          name: "monoSynth",
          type: "MonoSynth"
        },
        {
          name: "play",
          type: "Play"
        },
        {
          name: "options",
          type: "object",
          elements: [
            {
              name: "waveform",
              type: "union",
              elements: [
                { type: "square" },
                { type: "sawtooth" },
                { type: "triangle" },
                { type: "sine" }
              ]
            },
            { name: "attack", type: "number" },
            { name: "decay", type: "number" },
            { name: "sustain", type: "number" },
            { name: "release", type: "number" }
          ]
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "MonoSynth" }, { type: "Play" }]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceMonoSynthWithPlay requires a MonoSynth and a Play molecule. It triggers the note of the MonoSynth and returns a new mono synth (identical to the original) and the original Play molecule.

If the reducer does not receive a MonoSynth and a Play molecule, it returns a tuple of null values.`,
      example: `const {
  addRules,
  ReactionRule,
  MonoSynth,
  Play,
  reduceMonoSynthWithPlay
} = ionian

const monoSynthRule = new ReactionRule()
monoSynthRule
  .replace([MonoSynth, Play])
  .by((first, second) => {
    const [newMonoSynth, play] = reduceMonoSynthWithPlay(first, second, {
      waveform: "sine",
      attack: 2,
      decay: 0.001,
      sustain: 0.002,
      release: 4
    })
    return [newMonoSynth, play]
  })
  .if(() => true)

addRules(monoSynthRule)`
    },
    {
      name: "reduceMonoSynthWithEffect",
      input: [
        {
          name: "effect",
          type: "union",
          elements: EFFECTS
        },
        {
          name: "monoSynth",
          type: "MonoSynth"
        },
        {
          name: "effect",
          type: "union",
          elements: EFFECTS
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [
                { type: "MonoSynth" },
                {
                  type: "union",
                  elements: EFFECTS
                }
              ]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceMonoSynthWithEffect requires a MonoSynth and an effect molecule. It creates a new MonoSynth with the effect added to the effect chain and returns the new mono synth and the original effect.

If the reducer does not receive a MonoSynth and an effect molecule, it returns a tuple of null values.`,
      example: `const {
  addRules,
  ReactionRule,
  MonoSynth,
  Reverb,
  reduceMonoSynthWithEffect
} = ionian

const monoSynthRule = new ReactionRule()
monoSynthRule
  .replace([MonoSynth, Reverb])
  .by((first, second) => {
    const [newMonoSynth, reverb] = reduceMonoSynthWithEffect(
      Reverb,
      first,
      second
    )
    return [newMonoSynth, reverb]
  })
  .if(() => true)

addRules(monoSynthRule)`
    },
    {
      name: "reduceChordWithOctave",
      input: [
        {
          name: "chord",
          type: "Chord"
        },
        {
          name: "octave",
          type: "Octave"
        }
      ],
      returns: [
        {
          type: "union",
          elements: [
            {
              type: "tuple",
              elements: [{ type: "Chord" }, { type: "Octave" }]
            },
            {
              type: "tuple",
              elements: [{ type: "null" }, { type: "null" }]
            }
          ]
        }
      ],
      description: `reduceChordWithOctave requires a Chord and an Octave molecule. It creates a new Chord where its octave property points to the provided Octave. It returns the new chord and the original octave.

If the reducer does not receive a Chord and an Octave molecule, it returns a tuple of null values.`,
      example: `const { addRules, ReactionRule, Chord, Octave, reduceChordWithOctave } = ionian

const chordRule = new ReactionRule()
chordRule
  .replace([Chord, Octave])
  .by((first, second) => {
    const [newChord, octave] = reduceChordWithOctave(first, second)
    return [newChord, octave]
  })
  .if(() => true)

addRules(chordRule)`
    }
  ],
  utilityFunctions: [
    {
      name: "extract",
      input: [
        {
          name: "type",
          type: "union",
          elements: MOLECULES
        },
        {
          name: "first",
          type: "Molecule"
        },
        {
          name: "second",
          type: "Molecule"
        }
      ],
      returns: [
        {
          type: "union",
          elements: MOLECULES
        }
      ],
      description:
        "extract takes a type that specifies which molecule type we want to extract and two molecules. If a match is found, it returns the molecule, otherwise, it returns null.",
      example: {
        description: `Remove all notes in the C major scale.`,
        code: `const { addRules, ReactionRule, Note, Clean, extract, isNoteInScale } = ionian

const removeNotes = new ReactionRule()
removeNotes
  .replace([Clean, Note])
  .by([Clean])
  .if((first, second) => {
    const note = extract(Note, first, second)
    return isNoteInScale(note, "C", "major")
  })

addRules(removeNotes)`
      }
    },
    {
      name: "effectChainLength",
      input: [
        {
          name: "audioSource",
          type: "union",
          elements: AUDIO_SOURCES
        }
      ],
      returns: [{ type: "number" }],
      description:
        "effectChainLength takes either an Oscillator, a MonoSynth or a Synth and calculates the length of the effect chain.",
      example: {
        description:
          "Add a PingPongDelay if the effect chain contains below three effects.",
        code: `const {
  addRules,
  ReactionRule,
  Oscillator,
  PingPongDelay,
  reduceOscillatorWithEffect,
  extract,
  effectChainLength
} = ionian

const effects = new ReactionRule()
effects
  .replace([Oscillator, PingPongDelay])
  .by((first, second) => {
    const [newOscillator, pingPongDelay] = reduceOscillatorWithEffect(PingPongDelay, first, second)
    return [newOscillator, pingPongDelay]
  })
  .if((first, second) => {
    const oscillator = extract(Oscillator, first, second)
    return effectChainLength(oscillator) < 3
  })

addRules(effects)`
      }
    },
    {
      name: "isEffectInChain",
      input: [
        {
          name: "audioSource",
          type: "union",
          elements: AUDIO_SOURCES
        },
        { name: "effect", type: "union", elements: EFFECTS }
      ],
      returns: [{ type: "boolean" }],
      description:
        "isEffectInChain takes either an Oscillator, a MonoSynth or a Synth and returns true if the effect type is already in the effect chain, otherwise, it returns false.",
      example: {
        description:
          "Remove the Chorus molecule if the Oscillator already has a Chorus in its effect chain.",
        code: `const {
  addRules,
  ReactionRule,
  Oscillator,
  Chorus,
  extract,
  isEffectInChain
} = ionian

const effect = new ReactionRule()
effect
  .replace([Oscillator, Chorus])
  .by([Oscillator])
  .if((first, second) => {
    const oscillator = extract(Oscillator, first, second)
    const chorus = extract(Chorus, first, second)
    return isEffectInChain(oscillator, chorus)
  })

addRules(effect)`
      }
    },
    {
      name: "fetchJSON",
      input: [{ name: "url", type: "URL" }],
      returns: [
        {
          type: "union",
          elements: [{ type: "JSON" }, { type: "null" }]
        }
      ],
      description: `fetchJSON takes a URL, sends a request and returns the JSON output if the request is succesful, otherwise, it returns null. Since the condition needs to be checked before we can compute the result of the reaction between the two molecules, fetchJSON runs synchronously. This means that it will block everything until the condition has been resolved.`,
      example: {
        description:
          "Remove the Play molecule if a todo requested from a JSON API has been completed.",
        code: `const { addRules, ReactionRule, Play, Oscillator, fetchJSON } = ionian

const fetchRule = new ReactionRule()
fetchRule
  .replace([Oscillator, Play])
  .by([Oscillator])
  .if(() => {
    const json = fetchJSON("https://jsonplaceholder.typicode.com/todos/1")
    return json["completed"] === true
  })

addRules(fetchRule)`
      }
    },
    {
      name: "isChordInKey",
      input: [
        { name: "chord", type: "Chord" },
        { name: "note", type: "union", elements: NOTES },
        {
          name: "key",
          type: "union",
          elements: KEYS
        }
      ],
      returns: [{ type: "boolean" }],
      description:
        "isChordInKey takes a Chord, a note, and a key and returns true if the chord is in a given key, otherwise, it returns false.",
      example: {
        description: "Remove any chord that is not in the key of A minor.",
        code: `const { addRules, ReactionRule, Chord, Synth, extract, isChordInKey } = ionian

const chordRule = new ReactionRule()
chordRule
  .replace([Chord, Synth])
  .by([Synth])
  .if((first, second) => {
    const chord = extract(Chord, first, second)
    return !isChordInKey(chord, "A", "minor")
  })

addRules(chordRule)`
      }
    },
    {
      name: "isNoteInScale",
      input: [
        { name: "note", type: "Note" },
        { name: "key", type: "union", elements: NOTES },
        {
          name: "scale",
          type: "union",
          elements: KEYS
        }
      ],
      returns: [{ type: "boolean" }],
      description:
        "isNoteInScale takes a Note, a key, and a scale and returns true if the note is in a given scale, otherwise, it returns false.",
      example: {
        description: "Remove any notes that are not in the C major scale.",
        code: `const {
  addRules,
  ReactionRule,
  Note,
  Clean,
  extract,
  isNoteInScale
} = ionian

const removeNotes = new ReactionRule()
removeNotes
  .replace([Clean, Note])
  .by([Clean])
  .if((first, second) => {
    const note = extract(Note, first, second)
    return !isNoteInScale(note, "C", "major")
  })

addRules(removeNotes)`
      }
    },
    {
      name: "isOctaveInRange",
      input: [
        { name: "octave", type: "Octave" },
        { name: "start", type: "number" },
        { name: "end", type: "number" }
      ],
      returns: [{ type: "boolean" }],
      description:
        "isOctaveInRange takes an Octave, a start octave, and an end octave. It returns true if the Octave is between the start and end octave (inclusive), otherwise, it returns false.",
      example: {
        description: "Remove any octave that is not between 3 and 5.",
        code: `const {
  addRules,
  ReactionRule,
  Octave,
  Clean,
  extract,
  isOctaveInRange
} = ionian

const removeOctaves = new ReactionRule()
removeOctaves
  .replace([Clean, Octave])
  .by([Clean])
  .if((first, second) => {
    const octave = extract(Octave, first, second)
    return !isOctaveInRange(octave, 3, 5)
  })

addRules(removeOctaves)`
      }
    },
    {
      name: "print",
      input: [{ name: "input", type: "any" }],
      returns: [{ type: "string" }],
      description:
        "print takes an input and prints it in the console below the editor. This is mostly useful for simple debugging when testing reaction rules. It is globally available and should not be exported from the ionian object.",
      example: {
        description: "Print message when Play and Clean collides.",
        code: `const { addRules, ReactionRule, Play, Clean } = ionian

const time = new ReactionRule()
time
  .replace([Play, Clean])
  .by([Play, Clean])
  .if((first, second) => {
    print("Play and clean collided!")
    return true
  })

addRules(time)`
      }
    },
    {
      name: "randomInRange",
      input: [{ name: "min", type: "number" }, { name: "max", type: "number" }],
      returns: [{ type: "number" }],
      description:
        "randomInRange takes a minimum (inclusive) and maximum (exclusive) and returns a random number which is between the two.",
      example: {
        description: "Print a random number when Note and Clean collides.",
        code: `const { addRules, ReactionRule, Note, Clean, randomInRange } = ionian

const randomNumber = new ReactionRule()
randomNumber
  .replace([Note, Clean])
  .by([Note, Clean])
  .if(() => {
    print(randomInRange(1, 5))
    return true
  })

addRules(randomNumber)`
      }
    },
    {
      name: "randomFloatInRange",
      input: [{ name: "min", type: "number" }, { name: "max", type: "number" }],
      returns: [{ type: "number" }],
      description:
        "randomFloatInRange takes a minimum (inclusive) and maximum (exclusive) and returns a random floating number which is between the two.",
      example: {
        description:
          "Print a random floating number when Note and Clean collides.",
        code: `const { addRules, ReactionRule, Note, Clean, randomFloatInRange } = ionian

const randomNumber = new ReactionRule()
randomNumber
  .replace([Note, Clean])
  .by([Note, Clean])
  .if(() => {
    print(randomFloatInRange(1, 5))
    return true
  })

addRules(randomNumber)`
      }
    }
  ]
}

export default docs
