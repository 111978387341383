// @flow
import { createMolecule } from "../molecules"
import { randomPosition } from "../helpers"
import { DEFAULT_MASS, HEIGHT_DIVIDER, SPEED } from "../constants"
import type { Molecule, MoleculeString, EffectString } from "../molecules"

export type Node = {|
  x: number,
  y: number,
  velocity: {
    x: number,
    y: number
  },
  mass: number,
  color: string,
  opacity: number,
  active: boolean,
  value: string,
  molecule: Molecule
|}

const angleBetweenNodes = (node1: Node, node2: Node): number =>
  -Math.atan2(node2.y - node1.y, node2.x - node1.x)

const rotateNode = (velocity: { x: number, y: number }, angle: number) => ({
  x: velocity.x * Math.cos(angle) - velocity.y * Math.sin(angle),
  y: velocity.x * Math.sin(angle) + velocity.y * Math.cos(angle)
})

const colliding = (
  x1: number,
  y1: number,
  radius1: number,
  x2: number,
  y2: number,
  radius2: number
) => {
  const dx = x1 - x2
  const dy = y1 - y2
  const distance = Math.sqrt(dx * dx + dy * dy)
  const radius = (radius1 + radius2) / 2

  if (distance <= radius * 2) return true

  return false
}

export const isColliding = (first: Node, second: Node) => {
  return colliding(
    first.x,
    first.y,
    first.molecule.radius,
    second.x,
    second.y,
    second.molecule.radius
  )
}

export const resolveCollision = (node1: Node, node2: Node) => {
  const deltaVelocityX = node1.velocity.x - node2.velocity.x
  const deltaVelocityY = node1.velocity.y - node2.velocity.y
  const dx = node2.x - node1.x
  const dy = node2.y - node1.y

  // See https://en.wikipedia.org/wiki/Elastic_collision
  if (deltaVelocityX * dx + deltaVelocityY * dy >= 0) {
    const angle = angleBetweenNodes(node1, node2)
    const m1 = node1.mass
    const m2 = node2.mass

    // Velocity before the equation
    const u1 = rotateNode(node1.velocity, angle)
    const u2 = rotateNode(node2.velocity, angle)

    // Velocity after the collision
    const v1 = {
      x: (u1.x * (m1 - m2)) / (m1 + m2) + (u2.x * 2 * m2) / (m1 + m2),
      y: u1.y
    }
    const v2 = {
      x: (u2.x * (m1 - m2)) / (m1 + m2) + (u1.x * 2 * m2) / (m1 + m2),
      y: u2.y
    }

    // Rotate the velocities back to their original angle
    const newVelocity1 = rotateNode(v1, -angle)
    const newVelocity2 = rotateNode(v2, -angle)
    const newX1 = node1.x + node1.velocity.x
    const newY1 = node1.y + node1.velocity.y
    const newX2 = node2.x + node2.velocity.x
    const newY2 = node2.y + node2.velocity.y

    return {
      v1: newVelocity1,
      v2: newVelocity2,
      x1: newX1,
      y1: newY1,
      x2: newX2,
      y2: newY2
    }
  }

  return {
    v1: { x: node1.velocity.x, y: node1.velocity.y },
    v2: { x: node2.velocity.x, y: node2.velocity.y },
    x1: node1.x,
    y1: node1.y,
    x2: node2.x,
    y2: node2.y
  }
}

export const createNode = (nodes: Node[]): Node => {
  const molecule = createMolecule()
  const velocity = {
    x: (Math.random() - 0.5) * SPEED,
    y: (Math.random() - 0.5) * SPEED
  }
  let { x, y } = randomPosition(molecule.radius)

  for (let i = 0; i < nodes.length; i++) {
    if (
      colliding(
        x,
        y,
        molecule.radius,
        nodes[i].x,
        nodes[i].y,
        nodes[i].molecule.radius
      )
    ) {
      x = randomPosition(molecule.radius).x
      y = randomPosition(molecule.radius).y
      i = -1
    }
  }

  return {
    x,
    y,
    velocity,
    mass: DEFAULT_MASS,
    color: molecule.color,
    opacity: 1,
    active: true,
    value: molecule.text,
    molecule
  }
}

export const updateNode = (node: Node, width: number, height: number): Node => {
  const {
    x,
    y,
    molecule: { radius },
    velocity
  } = node
  const newVelocity = velocity

  if (x - radius <= 0 || x + radius >= window.innerWidth) {
    newVelocity.x = -velocity.x
  }

  if (y - radius <= 0 || y + radius >= window.innerHeight / HEIGHT_DIVIDER) {
    newVelocity.y = -velocity.y
  }

  const newX = x + newVelocity.x
  const newY = y + newVelocity.y

  return {
    ...node,
    x: newX,
    y: newY,
    velocity: newVelocity
  }
}

export const connected = (first: Node, second: Node) => {
  if (first.molecule.type === "oscillator" && second.molecule.type === "note") {
    return first.molecule.data.note.id === second.molecule.id
  }

  if (second.molecule.type === "oscillator" && first.molecule.type === "note") {
    return second.molecule.data.note.id === first.molecule.id
  }

  if (first.molecule.type === "synth" && second.molecule.type === "chord") {
    return first.molecule.data.chord.id === second.molecule.id
  }

  if (second.molecule.type === "synth" && first.molecule.type === "chord") {
    return second.molecule.data.chord.id === first.molecule.id
  }

  if (first.molecule.type === "monoSynth" && second.molecule.type === "note") {
    return first.molecule.data.note.id === second.molecule.id
  }

  if (second.molecule.type === "monoSynth" && first.molecule.type === "note") {
    return second.molecule.data.note.id === first.molecule.id
  }

  if (first.molecule.type === "chord" && second.molecule.type === "octave") {
    return first.molecule.data.octave.id === second.molecule.id
  }

  if (second.molecule.type === "chord" && first.molecule.type === "octave") {
    return second.molecule.data.octave.id === first.molecule.id
  }

  if (first.molecule.type === "note" && second.molecule.type === "octave") {
    return first.molecule.data.octave.id === second.molecule.id
  }

  if (second.molecule.type === "note" && first.molecule.type === "octave") {
    return second.molecule.data.octave.id === first.molecule.id
  }

  if (
    (first.molecule.type === "oscillator" ||
      first.molecule.type === "synth" ||
      first.molecule.type === "monoSynth") &&
    second.molecule.type === "effect"
  ) {
    return first.molecule.data.effects
      .map(({ id }) => id)
      .includes(second.molecule.id)
  }

  if (
    (second.molecule.type === "oscillator" ||
      second.molecule.type === "synth" ||
      second.molecule.type === "monoSynth") &&
    first.molecule.type === "effect"
  ) {
    return second.molecule.data.effects
      .map(({ id }) => id)
      .includes(first.molecule.id)
  }
}

export const lookupType: (Node, Node) => MoleculeString[] = (first, second) => {
  if (first.molecule.type === "effect" && second.molecule.type === "effect") {
    return [first.molecule.data.effectType, second.molecule.data.effectType]
  }

  if (first.molecule.type === "effect") {
    return [first.molecule.data.effectType, second.molecule.type]
  }

  if (second.molecule.type === "effect") {
    return [first.molecule.type, second.molecule.data.effectType]
  }

  return [first.molecule.type, second.molecule.type]
}

export const checkEffectTypes: (Node, Node) => ?EffectString = (
  first,
  second
) => {
  if (first.molecule.type === "effect") return first.molecule.data.effectType
  if (second.molecule.type === "effect") return second.molecule.data.effectType
  return null
}

export const checkMatch: (MoleculeString[], Node, Node) => boolean = (
  molecules,
  first,
  second
) => {
  if (!first || !second) return false

  return molecules.every(molecule =>
    [
      first.molecule.type,
      second.molecule.type,
      checkEffectTypes(first, second),
      checkEffectTypes(first, second)
    ].includes(molecule)
  )
}
