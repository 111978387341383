// @flow
import * as React from "react"
import Button from "./Button"

const ToggleableButton = ({
  isOn,
  onClick,
  children
}: {
  isOn: boolean,
  onClick: (e: SyntheticEvent<HTMLButtonElement>) => void,
  children?: React.Node
}) => (
  <Button onClick={onClick}>
    <span>
      {isOn ? "Hide" : "Show"} {children}
    </span>
  </Button>
)

export default ToggleableButton
