// @flow
import React from "react"
import styled from "styled-components"
import { slugify } from "../../helpers"
import type { ComponentType } from "react"

const Link: ComponentType<{}> = styled.a`
  color: ${({ theme }) => theme.colors.blue};
  padding: 0.2em 0;
  margin: 0;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

const Reference = ({ children }: { children: string }) => {
  const scrollToContent = () => {
    const element = document.getElementById(slugify(children))
    element && element.scrollIntoView()
  }

  return <Link onClick={scrollToContent}>{children}</Link>
}

export default Reference
