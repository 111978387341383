// @flow
import styled from "styled-components"
import type { ComponentType } from "react"

const Slider: ComponentType<{}> = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.white};
  margin: 0;

  ::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: ${({ theme }) => theme.colors.gray};
    padding: 5px;
    border: none;
    border-radius: 3px;
  }

  ::-moz-range-track {
    width: 100%;
    height: 10px;
    background: ${({ theme }) => theme.colors.gray};
    border: none;
    border-radius: 3px;
  }

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.blue};
    margin-top: -8px;
    cursor: grab;
  }

  ::-moz-range-thumb {
    -webkit-appearance: none;
    border: none;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.blue};
    margin-top: -8px;
    cursor: grab;
  }

  :focus {
    outline: none;
  }

  :-moz-focusring {
    outline: none;
    outline-offset: -1px;
  }

  :focus::-moz-range-track {
    background: ${({ theme }) => theme.colors.gray};
  }

  :focus::-webkit-slider-runnable-track {
    background: ${({ theme }) => theme.colors.gray};
  }
`

export default Slider
