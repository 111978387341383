// @flow
import { uuidv4 } from "../../helpers"
import { DEFAULT_NOTE, toJSON as noteToJSON } from "../note"
import { toJSON as effectToJSON } from "../effects"
import type { Note, JSON as NoteJSON } from "../note"
import type { Effect, JSON as EffectJSON } from "../effects"

export const COLOR = "#E6194B"
const OSCILLATOR_TEXT = "📣"
const RADIUS = 26
const DEFAULT_EFFECTS = []

export type OscillatorParams = {|
  id: string,
  note: Note,
  isActive: boolean,
  effects: Effect[]
|}

export type Oscillator = {|
  +type: "oscillator",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    note: Note,
    isActive: boolean,
    effects: Effect[]
  }
|}

export type JSON = $ReadOnly<{|
  type: "oscillator",
  id: string,
  data: {|
    note: NoteJSON,
    isActive: boolean,
    effects: EffectJSON[]
  |}
|}>

export const createOscillator = (
  { id, note, isActive, effects }: OscillatorParams = {
    id: uuidv4(),
    note: DEFAULT_NOTE,
    isActive: false,
    effects: DEFAULT_EFFECTS
  }
) => ({
  type: "oscillator",
  id,
  color: COLOR,
  text: OSCILLATOR_TEXT,
  radius: RADIUS,
  data: {
    note,
    isActive,
    effects
  }
})

export const toJSON: Oscillator => JSON = oscillator => {
  const {
    type,
    id,
    data: { note, isActive, effects }
  } = oscillator

  return {
    type,
    id,
    data: {
      note: noteToJSON(note),
      isActive,
      effects: effects.map(effectToJSON)
    }
  }
}
