// @flow
import React from "react"
import styled from "styled-components"
import Molecules from "./Molecules"
import ReactionRules from "./ReactionRules"
import Reducers from "./Reducers"
import Reference from "./Reference"
import UtilityFunctions from "./UtilityFunctions"
import Performance from "./Performance"
import { Subheader, Divider, Column } from "../shared"
import { slugify } from "../../helpers"

const Header = styled.h1`
  margin: 0.5em 0;
`

const List = styled.ul`
  margin: 0;
`

const ListElement = styled.li``

const Wrapper = styled.main`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  border-left: 1px solid ${({ theme }) => theme.colors.gray};
  padding: 0 1em;
  min-height: 40.5vh;
  max-height: 40.5vh;
  overflow: auto;
`

const StyledDocs = styled.div`
  display: flex;
  flex-direction: column;
`

type Props = {
  isOn: boolean
}

const Docs = React.memo<Props>((props: Props) => (
  <Column isOn={props.isOn}>
    <Wrapper>
      <StyledDocs>
        <Header>Documentation</Header>
        <Subheader>Table of contents</Subheader>
        <List>
          <ListElement>
            <Reference>Molecules</Reference>
          </ListElement>
          <ListElement>
            <Reference>Reaction rules</Reference>
          </ListElement>
          <ListElement>
            <Reference>Reducers</Reference>
          </ListElement>
          <ListElement>
            <Reference>Utility functions</Reference>
          </ListElement>
          <ListElement>
            <Reference>Performance</Reference>
          </ListElement>
        </List>
        <Divider />
        <Subheader id={slugify("molecules")}>Molecules</Subheader>
        <Molecules />
        <Subheader id={slugify("reaction-rules")}>Reaction rules</Subheader>
        <ReactionRules />
        <Subheader id={slugify("reducers")}>Reducers</Subheader>
        <Reducers />
        <Subheader id={slugify("utility functions")}>
          Utility functions
        </Subheader>
        <UtilityFunctions />
        <Subheader id={slugify("performance")}>Performance</Subheader>
        <Performance />
      </StyledDocs>
    </Wrapper>
  </Column>
))

export default Docs
