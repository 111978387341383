// @flow
import React from "react"
import styled from "styled-components"
import Example from "./Example"
import Snippet from "./Snippet"
import TextWithReferences from "./TextWithReferences"
import { parseTypes } from "./parseTypes"
import { Subheader, Code, Table, Divider } from "../shared"
import { slugify } from "../../helpers"
import docs from "./docs"

const Bold = styled.b`
  display: block;
  margin: 0.5em 0;
`

const UtilityFunctions = () => (
  <>
    {docs.utilityFunctions
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        return 0
      })
      .map((utilityFunction, index) => {
        return (
          <div key={index}>
            <Subheader id={slugify(utilityFunction.name)}>
              <Code>{utilityFunction.name}</Code>
            </Subheader>
            <Table
              headers={["Name", "Type"]}
              data={parseTypes(utilityFunction.input)}
            />
            <Bold>Returns:</Bold>
            {parseTypes(utilityFunction.returns).map((type, index) => (
              <React.Fragment key={index}>{type.type}</React.Fragment>
            ))}
            <TextWithReferences>
              {utilityFunction.description}
            </TextWithReferences>
            <Example
              description={
                <TextWithReferences>
                  {utilityFunction.example.description}
                </TextWithReferences>
              }
            >
              <Snippet>{utilityFunction.example.code}</Snippet>
            </Example>
            <Divider />
          </div>
        )
      })}
  </>
)

export default UtilityFunctions
