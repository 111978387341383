// @flow
import Tone from "tone"
import { extractEffect, extractMolecule } from "./extractMolecule"
import {
  createOscillator,
  createEffect,
  createSynth,
  createMonoSynth,
  createChord,
  createNote
} from "../molecules"
import type {
  EffectString,
  Molecule,
  Effect,
  Oscillator,
  Note,
  Play,
  Synth,
  Chord,
  Octave,
  MonoSynth
} from "../molecules"
import {
  DEFAULT_ATTACK,
  DEFAULT_DECAY,
  DEFAULT_SUSTAIN,
  DEFAULT_RELEASE,
  DEFAULT_WAVEFORM
} from "../constants"
import type { Waveform } from "../molecules"

const UNRESOLVABLE = [null, null]

const isMatch = (first: Molecule, second: Molecule, types: [string, string]) =>
  (first.type === types[0] && second.type === types[1]) ||
  (second.type === types[0] && first.type === types[1])

const isEffectMatch = (
  first: Molecule,
  second: Molecule,
  effectType: EffectString
) => {
  const audioSources = ["oscillator", "synth", "monoSynth"]

  return (
    (first.type === "effect" &&
      first.data.effectType === effectType &&
      audioSources.includes(second.type)) ||
    (second.type === "effect" &&
      second.data.effectType === effectType &&
      audioSources.includes(first.type))
  )
}

const connected = (
  audioSource: Oscillator | Synth | MonoSynth,
  effect: Effect
) => audioSource.data.effects.map(({ id }) => id).includes(effect.id)

export const reduceOscillatorWithNote = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["oscillator", "note"])) {
    let oscillator = extractMolecule<Oscillator>("oscillator", first, second)
    let note = extractMolecule<Note>("note", first, second)
    const {
      id,
      data: { isActive, effects }
    } = oscillator
    if (isActive) return [oscillator, note]

    return [createOscillator({ id, note, isActive, effects }), note]
  }

  return UNRESOLVABLE
}

export const reduceOscillatorWithPlay = (
  first: Molecule,
  second: Molecule,
  options: { waveform: Waveform } = { waveform: DEFAULT_WAVEFORM }
) => {
  if (isMatch(first, second, ["oscillator", "play"])) {
    let oscillator = extractMolecule<Oscillator>("oscillator", first, second)
    let play = extractMolecule<Play>("play", first, second)
    const {
      id,
      data: { note, isActive, effects }
    } = oscillator
    const { waveform } = options
    const octave = note.data.octave.data.octave
    if (isActive) return [oscillator, play]

    const volume = [...Array(effects.length).keys()].reduce(
      (acc, _) => acc - 4,
      -6
    )
    const effectNodes = effects.map(({ data: { effectType } }) =>
      createEffect(effectType)
    )
    const oscillatorNode = new Tone.Oscillator(
      `${note.data.note}${octave}`,
      waveform || DEFAULT_WAVEFORM
    ).toMaster()

    oscillatorNode.chain(...effectNodes, Tone.Master)
    oscillatorNode.volume.value = volume
    oscillatorNode.start()

    return [createOscillator({ id, note, isActive: true, effects }), play]
  }

  return UNRESOLVABLE
}

export const reduceOscillatorWithEffect = (
  type: EffectString,
  first: Molecule,
  second: Molecule
) => {
  if (isEffectMatch(first, second, type)) {
    let oscillator = extractMolecule<Oscillator>("oscillator", first, second)
    let effect = extractEffect(type, first, second)
    const {
      id,
      data: { note, isActive, effects }
    } = oscillator
    const newEffects = connected(oscillator, effect)
      ? effects
      : [...effects, effect]

    return [
      createOscillator({
        id,
        note,
        isActive,
        effects: newEffects
      }),
      effect
    ]
  }

  return UNRESOLVABLE
}

export const reduceSynthWithChord = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["synth", "chord"])) {
    let synth = extractMolecule<Synth>("synth", first, second)
    let chord = extractMolecule<Chord>("chord", first, second)

    const {
      id,
      data: { effects }
    } = synth

    return [createSynth({ id, chord, effects }), chord]
  }

  return UNRESOLVABLE
}

type OptionsParams = {
  waveform: Waveform,
  attack: number,
  decay: number,
  sustain: number,
  release: number
}

export const reduceSynthWithPlay = (
  first: Molecule,
  second: Molecule,
  options: OptionsParams = {
    waveform: DEFAULT_WAVEFORM,
    attack: DEFAULT_ATTACK,
    decay: DEFAULT_DECAY,
    sustain: DEFAULT_SUSTAIN,
    release: DEFAULT_RELEASE
  }
) => {
  if (isMatch(first, second, ["synth", "play"])) {
    let synth = extractMolecule<Synth>("synth", first, second)
    let play = extractMolecule<Play>("play", first, second)
    const { chord, effects } = synth.data
    const { octave } = chord.data.octave.data
    const { waveform, attack, decay, sustain, release } = options
    const notes = chord.data.notes.map(note => `${note}${octave}`)
    const volume = [...Array(effects.length).keys()].reduce(
      (acc, _) => acc - 4,
      -6
    )

    const effectNodes = effects.map(({ data: { effectType } }) =>
      createEffect(effectType)
    )
    const synthNode = new Tone.PolySynth(4, Tone.Synth, {
      oscillator: {
        type: waveform
      },
      envelope: {
        attack: attack || DEFAULT_ATTACK,
        decay: decay || DEFAULT_DECAY,
        sustain: sustain || DEFAULT_SUSTAIN,
        release: release || DEFAULT_RELEASE
      }
    }).toMaster()

    synthNode.chain(...effectNodes, Tone.Master)
    synthNode.volume.value = volume
    synthNode.triggerAttackRelease(notes, "2n")

    return [synth, play]
  }

  return UNRESOLVABLE
}

export const reduceSynthWithEffect = (
  type: EffectString,
  first: Molecule,
  second: Molecule
) => {
  if (isEffectMatch(first, second, type)) {
    let synth = extractMolecule<Synth>("synth", first, second)
    let effect = extractEffect(type, first, second)
    const {
      id,
      data: { chord, effects }
    } = synth
    const newEffects = connected(synth, effect) ? effects : [...effects, effect]

    return [createSynth({ id, chord, effects: newEffects }), effect]
  }

  return UNRESOLVABLE
}

export const reduceMonoSynthWithPlay = (
  first: Molecule,
  second: Molecule,
  options: OptionsParams = {
    waveform: DEFAULT_WAVEFORM,
    attack: DEFAULT_ATTACK,
    decay: DEFAULT_DECAY,
    sustain: DEFAULT_SUSTAIN,
    release: DEFAULT_RELEASE
  }
) => {
  if (isMatch(first, second, ["monoSynth", "play"])) {
    let monoSynth = extractMolecule<MonoSynth>("monoSynth", first, second)
    let play = extractMolecule<Play>("play", first, second)
    const { note, effects } = monoSynth.data
    const { octave } = note.data.octave.data
    const { waveform, attack, decay, sustain, release } = options
    const volume = [...Array(effects.length).keys()].reduce(
      (acc, _) => acc - 4,
      -6
    )

    const effectNodes = effects.map(({ data: { effectType } }) =>
      createEffect(effectType)
    )
    const monoSynthNode = new Tone.Synth({
      oscillator: {
        type: waveform
      },
      envelope: {
        attack: attack || DEFAULT_ATTACK,
        decay: decay || DEFAULT_DECAY,
        sustain: sustain || DEFAULT_SUSTAIN,
        release: release || DEFAULT_RELEASE
      }
    }).toMaster()

    monoSynthNode.chain(...effectNodes, Tone.Master)
    monoSynthNode.volume.value = volume
    monoSynthNode.triggerAttackRelease(`${note.data.note}${octave}`, "2n")

    return [monoSynth, play]
  }

  return UNRESOLVABLE
}

export const reduceMonoSynthWithNote = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["monoSynth", "note"])) {
    let monoSynth = extractMolecule<MonoSynth>("monoSynth", first, second)
    let note = extractMolecule<Note>("note", first, second)

    const {
      id,
      data: { effects }
    } = monoSynth

    return [createMonoSynth({ id, note, effects }), note]
  }

  return UNRESOLVABLE
}

export const reduceMonoSynthWithEffect = (
  type: EffectString,
  first: Molecule,
  second: Molecule
) => {
  if (isEffectMatch(first, second, type)) {
    let monoSynth = extractMolecule<MonoSynth>("monoSynth", first, second)
    let effect = extractEffect(type, first, second)
    const {
      id,
      data: { note, effects }
    } = monoSynth
    const newEffects = connected(monoSynth, effect)
      ? effects
      : [...effects, effect]

    return [createMonoSynth({ id, note, effects: newEffects }), effect]
  }

  return UNRESOLVABLE
}

export const reduceChordWithOctave = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["chord", "octave"])) {
    const {
      id,
      data: { notes }
    } = extractMolecule<Chord>("chord", first, second)
    const octave = extractMolecule<Octave>("octave", first, second)
    const newChord = createChord({ id, notes, octave })

    return [newChord, octave]
  }

  return UNRESOLVABLE
}

export const reduceNoteWithOctave = (first: Molecule, second: Molecule) => {
  if (isMatch(first, second, ["note", "octave"])) {
    const {
      id,
      data: { note }
    } = extractMolecule<Note>("note", first, second)
    const octave = extractMolecule<Octave>("octave", first, second)
    const newNote = createNote({ id, note, octave })

    return [newNote, octave]
  }

  return UNRESOLVABLE
}
