// @flow
import React from "react"
import styled from "styled-components"
import Reference from "./Reference"
import { parseTypes } from "./parseTypes"
import { Subheader, Code, Divider, Row, Table } from "../shared"
import { slugify } from "../../helpers"
import docs from "./docs"
import type { ComponentType } from "react"

const Bold = styled.b`
  display: block;
  width: 100%;
  line-height: 1.5em;
  margin: 0.5em 0;
`

const Text: ComponentType<{}> = styled.p`
  line-height: 1.5em;
  margin: 0.4em 0;
`

const List = styled.ul`
  margin: 0;
`

const ListElement = styled.li`
  margin: 0.6em 0;
`

export const NoProperties = (props: { name: string }) => (
  <Text>
    <Code>{props.name}</Code> has no properties.
  </Text>
)

const Molecules = () => (
  <>
    <Text>
      Ionian provides a set of molecules that are characterized by unique
      properties. These molecules can interact with each other through reaction
      rules, and they can be passed to reducers and utility functions. Below is
      a list of all available molecules.
    </Text>
    <Divider />
    {docs.molecules
      .sort((a, b) => {
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        return 0
      })
      .map((molecule, index) => (
        <div key={index}>
          <Subheader id={slugify(molecule.name)}>
            <Code>{molecule.name}</Code>
          </Subheader>
          <Bold>Properties</Bold>
          {molecule.properties.length > 0 ? (
            <Table
              headers={["Name", "Type"]}
              data={parseTypes(molecule.properties)}
            />
          ) : (
            <NoProperties name={molecule.name} />
          )}
          {molecule.relatedReducersAndFunctions.length > 0 ? (
            <Row>
              <Bold>Related reducers and functions</Bold>
              <List>
                {molecule.relatedReducersAndFunctions
                  .sort((a, b) => {
                    if (a > b) {
                      return 1
                    }
                    if (a < b) {
                      return -1
                    }
                    return 0
                  })
                  .map((item, index) => (
                    <ListElement key={index}>
                      <Code>
                        <Reference>{item}</Reference>
                      </Code>
                    </ListElement>
                  ))}
              </List>
            </Row>
          ) : null}
          <Divider />
        </div>
      ))}
  </>
)

export default Molecules
