// @flow
import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { DEFAULT_FPS } from "../constants"
import { theme } from "./shared"
import { connected } from "../models/node"
import { hexToRgb } from "../helpers"
import { useRequestAnimationFrame } from "../hooks/useRequestAnimationFrame"
import type { ComponentType } from "react"
import type { Node } from "../models/node"

type Props = {
  callback?: () => void,
  isOn: boolean,
  nodes: Node[],
  width: number,
  height: number
}

const StyledCanvas: ComponentType<{
  width: number,
  height: number
}> = styled.canvas`
  padding-bottom: 5px;
  background: linear-gradient(
      to left,
      rgba(255, 195, 0, 1) 0%,
      rgba(250, 60, 76, 1) 100%
    )
    left bottom #f8f8fa no-repeat;
  background-size: 100% 5px;
  width: ${props => props.width.toString()};
  height: ${props => props.height.toString()};
`

const Canvas = (props: Props) => {
  const canvasRef: any = useRef(null)
  const { callback, isOn, nodes, width, height } = props

  useRequestAnimationFrame(callback, DEFAULT_FPS)

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas == null) return

    const context = canvas.getContext("2d")
    context.clearRect(0, 0, width, height)
    context.restore()

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].active === false) {
        nodes[i].opacity -= 0.05
      }

      if (nodes[i].opacity <= 0) {
        nodes.splice(i, 1)
      }

      if (!isOn) return

      for (let j = i + 1; j < nodes.length; j++) {
        if (connected(nodes[i], nodes[j])) {
          context.beginPath()
          context.moveTo(nodes[i].x, nodes[i].y)
          context.lineTo(nodes[j].x, nodes[j].y)
          context.lineWidth = 2
          context.strokeStyle = `rgba(${hexToRgb(theme.colors.darkGray)}, ${
            nodes[i].opacity
          })`
          context.stroke()
        }
      }
    }

    if (!isOn) return
    for (let i = 0; i < nodes.length; i++) {
      const {
        x,
        y,
        molecule: { radius },
        color,
        opacity,
        value
      } = nodes[i]

      context.beginPath()
      context.arc(x, y, radius, 0, 2 * Math.PI, false)
      context.fillStyle = `rgba(${hexToRgb(color)}, ${opacity})`
      context.fill()
      context.font = `1em ${theme.monospace.fontFamily}`
      context.fillStyle = `#FFF`
      context.textAlign = "center"
      context.fillText(value.toString(), x, y + 4)
    }

    context.save()
  }, [height, isOn, nodes, width])

  return <StyledCanvas ref={canvasRef} width={width} height={height} />
}

export default Canvas
