// @flow
import * as React from "react"
import styled from "styled-components"
import Reference from "./Reference"
import docs from "./docs"
import type { ComponentType } from "react"

const REFERENCES = Object.keys(docs)
  .flatMap(key => docs[key])
  .map(({ name }) => name)

const Wrapper: ComponentType<{}> = styled.span`
  font-family: ${({ theme }) => theme.monospace.fontFamily};
  background-color: ${({ theme }) => theme.colors.lightGray};
`

const UnionList: ComponentType<{}> = styled(Wrapper)``

const UnionListElement: ComponentType<{}> = styled.span`
  margin: 0;
  padding: 0;

  ::after {
    content: " | ";
  }

  :last-child::after {
    content: "";
  }
`

const ArrayList: ComponentType<{}> = styled(Wrapper)`
  ::before {
    content: "Array<";
  }

  ::after {
    content: ">";
  }
`

const TupleList: ComponentType<{}> = styled(Wrapper)`
  ::before {
    content: "[";
  }

  ::after {
    content: "]";
  }
`

const TupleListElement: ComponentType<{}> = styled.span`
  margin: 0;
  padding: 0;

  ::after {
    content: ", ";
  }

  :last-child::after {
    content: "";
  }
`

const ObjectList: ComponentType<{}> = styled.div`
  font-family: ${({ theme }) => theme.monospace.fontFamily};
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding: 0.2em;

  ::before {
    content: "{";
  }

  ::after {
    text-indent: -1.5em;
    content: "}";
  }
`

const ObjectListElement: ComponentType<{}> = styled.span`
  display: block;
  padding-left: 1.5em;

  ::after {
    content: ", ";
  }

  :last-child::after {
    content: "";
  }
`

const Link = ({ type }: { type: string }) =>
  REFERENCES.includes(type) ? <Reference>{type}</Reference> : <>{type}</>

const traverseType = (type: { type: string, elements: [] }) => {
  if (type.type === "union") {
    // TODO: Fix temporary fix for missing key
    return (
      <UnionList key={new Date().toString()}>
        {type.elements.map((element, index) => (
          <UnionListElement key={index}>
            {traverseType(element)}
          </UnionListElement>
        ))}
      </UnionList>
    )
  }

  if (type.type === "tuple") {
    return (
      <TupleList>
        {type.elements.map((element, index) => (
          <TupleListElement key={index}>
            {traverseType(element)}
          </TupleListElement>
        ))}
      </TupleList>
    )
  }

  if (type.type === "array") {
    return (
      <ArrayList>
        {type.elements.map(element => traverseType(element))}
      </ArrayList>
    )
  }

  if (type.type === "object") {
    return (
      <ObjectList>
        {type.elements.map((element, index) => (
          <ObjectListElement key={index}>
            <>{element.name}: </>
            {traverseType(element)}
          </ObjectListElement>
        ))}
      </ObjectList>
    )
  }

  return (
    <Wrapper>
      <Link type={type.type} />
    </Wrapper>
  )
}

type Types =
  // $FlowFixMe
  | { type: "union" | "tuple" | "array", elements: Types }[]
  | { type: string, name: string }[]

export const parseTypes = (types: Types) =>
  types.map<{ name: string, type: React.Node }>(type => ({
    name: type.name,
    type: traverseType(type)
  }))
