// @flow
const examples = [
  {
    title: "Simple example",
    content: `const { addRules, ReactionRule, Oscillator, Chorus } = ionian

const simpleRule = new ReactionRule()
simpleRule
  .replace([Oscillator, Chorus])
  .by([Oscillator])
  .if(() => true)

addRules(simpleRule)`
  },
  {
    title: "Conditional example",
    content: `const { addRules, ReactionRule, Oscillator, Chorus } = ionian

const conditionalRule = new ReactionRule()
conditionalRule
  .replace([Oscillator, Chorus])
  .by([Oscillator])
  .if((first, second) => {
    const seconds = new Date().getSeconds()
    return seconds % 2 === 0
  })

addRules(conditionalRule)`
  },
  {
    title: "Reducer example",
    content: `const {
  addRules,
  ReactionRule,
  Oscillator,
  Note,
  reduceOscillatorWithNote
} = ionian

const reducerRule = new ReactionRule()
reducerRule
  .replace([Oscillator, Note])
  .by((first, second) => {
    const [newOscillator, note] = reduceOscillatorWithNote(
      first,
      second
    )
    return [newOscillator, note]
  })
  .if(() => true)

addRules(reducerRule)`
  },
  {
    title: "Play oscillator example",
    content: `const {
  addRules,
  ReactionRule,
  Oscillator,
  Play,
  reduceOscillatorWithPlay
} = ionian

const playRule = new ReactionRule()
playRule
  .replace([Oscillator, Play])
  .by((first, second) => {
    const [newOscillator, play] = reduceOscillatorWithPlay(first, second)
    return [newOscillator, play]
  })
  .if(() => true)

addRules(playRule)`
  },
  {
    title: "Play synth example",
    content: `const { addRules, ReactionRule, Synth, Play, reduceSynthWithPlay } = ionian

const playRule = new ReactionRule()
playRule
  .replace([Synth, Play])
  .by((first, second) => {
    const [newSynth, play] = reduceSynthWithPlay(first, second)
    return [newSynth, play]
  })
  .if(() => true)

addRules(playRule)`
  },
  {
    title: "Play synth (and set envelope) example",
    content: `const { addRules, ReactionRule, Synth, Play, reduceSynthWithPlay } = ionian

const playRule = new ReactionRule()
playRule
  .replace([Synth, Play])
  .by((first, second) => {
    const [newSynth, play] = reduceSynthWithPlay(first, second, {
      waveform: "triangle",
      envelope: { attack: 0.5, decay: 1, sustain: 1, release: 5 }
    })
    return [newSynth, play]
  })
  .if(() => true)

addRules(playRule)`
  },
  {
    title: "Play mono synth example",
    content: `const {
  addRules,
  ReactionRule,
  MonoSynth,
  Play,
  reduceMonoSynthWithPlay
} = ionian

const playRule = new ReactionRule()
playRule
  .replace([MonoSynth, Play])
  .by((first, second) => {
    const [newMonoSynth, play] = reduceMonoSynthWithPlay(first, second)
    return [newMonoSynth, play]
  })
  .if(() => true)

addRules(playRule)`
  },
  {
    title: "Multiple rules example",
    content: `const {
  addRules,
  ReactionRule,
  Synth,
  Chord,
  Play,
  reduceSynthWithChord,
  reduceSynthWithPlay
} = ionian

const chordRule = new ReactionRule()
chordRule
  .replace([Synth, Chord])
  .by((first, second) => {
    const [newSynth, chord] = reduceSynthWithChord(first, second)
    return [newSynth, chord]
  })
  .if(() => true)

const playRule = new ReactionRule()
playRule
  .replace([Synth, Play])
  .by((first, second) => {
    const [newSynth, play] = reduceSynthWithPlay(first, second)
    return [newSynth, play]
  })
  .if(() => true)

addRules(chordRule, playRule)`
  },
  {
    title: "Remove chords not in A minor",
    content: `const { addRules, ReactionRule, Chord, Clean, extract, isChordInKey } = ionian

const removeChords = new ReactionRule()
removeChords
  .replace([Clean, Chord])
  .by([Clean])
  .if((first, second) => {
    const chord = extract(Chord, first, second)
    return !isChordInKey(chord, "A", "minor")
  })

addRules(removeChords)`
  },
  {
    title: "Remove notes not in the C major scale",
    content: `const { addRules, ReactionRule, Clean, Note, extract, isNoteInScale } = ionian

const removeNotes = new ReactionRule()
removeNotes
  .replace([Clean, Note])
  .by([Clean])
  .if((first, second) => {
    const note = extract(Note, first, second)
    return !isNoteInScale(note, "C", "major")
  })

addRules(removeNotes)`
  },
  {
    title: "Effect example",
    content: `const {
  addRules,
  ReactionRule,
  Oscillator,
  PingPongDelay,
  reduceOscillatorWithEffect,
  extract,
  isEffectInChain
} = ionian

const pingPongDelayRule = new ReactionRule()
pingPongDelayRule
  .replace([Oscillator, PingPongDelay])
  .by((first, second) => {
    const [newOscillator, pingPongDelay] = reduceOscillatorWithEffect(
      PingPongDelay,
      first,
      second
    )
    return [newOscillator, pingPongDelay]
  })
  .if((first, second) => {
    const oscillator = extract(Oscillator, first, second)
    const pingPongDelay = extract(PingPongDelay, first, second)
    const isInChain = isEffectInChain(oscillator, pingPongDelay)
    return !isInChain
  })

addRules(pingPongDelayRule)`
  },
  {
    title: "JSON example",
    content: `const { addRules, ReactionRule, Play, Oscillator, fetchJSON } = ionian

const fetchRule = new ReactionRule()
fetchRule
  .replace([Oscillator, Play])
  .by([Oscillator])
  .if(() => {
    const json = fetchJSON("https://jsonplaceholder.typicode.com/todos/1")
    return json["completed"] === true
  })

addRules(fetchRule)`
  }
]

export default examples
