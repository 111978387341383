// @flow
import { uuidv4 } from "../../helpers"
import { DEFAULT_NOTE, toJSON as noteToJSON } from "../note"
import { toJSON as effectToJSON } from "../effects"
import type { Note, JSON as NoteJSON } from "../note"
import type { Effect, JSON as EffectJSON } from "../effects"

export const COLOR = "#FFE119"
const MONO_SYNTH_TEXT = "🎵"
const RADIUS = 32
const DEFAULT_EFFECTS = []

export type MonoSynthParams = {|
  id: string,
  note: Note,
  effects: Effect[]
|}

export type MonoSynth = {|
  +type: "monoSynth",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    note: Note,
    effects: Effect[]
  }
|}

export type JSON = $ReadOnly<{|
  type: "monoSynth",
  id: string,
  data: {|
    note: NoteJSON,
    effects: EffectJSON[]
  |}
|}>

export const createMonoSynth = (
  { id, note, effects }: MonoSynthParams = {
    id: uuidv4(),
    note: DEFAULT_NOTE,
    effects: DEFAULT_EFFECTS
  }
) => ({
  type: "monoSynth",
  id,
  color: COLOR,
  text: MONO_SYNTH_TEXT,
  radius: RADIUS,
  data: {
    note,
    effects
  }
})

export const toJSON: MonoSynth => JSON = monoSynth => {
  const {
    type,
    id,
    data: { note, effects }
  } = monoSynth

  return {
    type,
    id,
    data: {
      note: noteToJSON(note),
      effects: effects.map(effectToJSON)
    }
  }
}
