// @flow
import { effects } from "../molecules"
import type {
  Effect,
  EffectString,
  Molecule,
  MoleculeString
} from "../molecules"

export const extractMolecule: <T: Molecule>(
  type: MoleculeString,
  first: Molecule,
  second: Molecule
) => T = (type, first, second) => {
  if (first.type === type) return first
  if (second.type === type) return second
  throw new Error(`Cannot match ${first.type} or ${second.type} with ${type}`)
}

export const extractEffect: (
  type: MoleculeString,
  first: Molecule,
  second: Molecule
) => Effect = (type, first, second) => {
  if (first.type === "effect" && first.data.effectType === type) return first
  if (second.type === "effect" && second.data.effectType === type) return second
  throw new Error(`Cannot match ${first.type} or ${second.type} with ${type}`)
}

export const extract = (
  type: MoleculeString | EffectString,
  first: Molecule,
  second: Molecule
) => {
  try {
    if (effects.includes(type)) {
      return extractEffect(type, first, second)
    } else {
      return extractMolecule(type, first, second)
    }
  } catch (e) {
    return null
  }
}
