// @flow
import * as React from "react"
import styled from "styled-components"
import type { ComponentType } from "react"

const Content = styled.div``

const Details: ComponentType<{}> = styled.details`
  display: block;
  margin: 1em 0;
  padding: 0;
`

const Summary = styled.summary`
  font-weight: bold;
  outline: none;
  cursor: pointer;
  display: block;
  margin: 0;
`

type Props = {
  title?: string,
  description?: React.Node,
  children?: React.Node
}

const Example = ({ title, description, children }: Props) => {
  return (
    <Details>
      <Summary>{title ? title : "Example"}</Summary>
      {description}
      <Content>{children}</Content>
    </Details>
  )
}

export default Example
