// @flow
import React from "react"
import styled from "styled-components"
import { Dropdown, Row, Column, Button, ToggleableButton } from "./shared"
import Volume from "./Volume"
import examples from "../examples"
import {
  createRandomChord,
  CHORD_COLOR,
  createChorus,
  CHORUS_COLOR,
  createNote,
  NOTE_COLOR,
  createOscillator,
  OSCILLATOR_COLOR,
  createPingPongDelay,
  PING_PONG_DELAY_COLOR,
  createPlay,
  PLAY_COLOR,
  createReverb,
  REVERB_COLOR,
  createSynth,
  SYNTH_COLOR,
  createClean,
  CLEAN_COLOR,
  createRandomOctave,
  OCTAVE_COLOR,
  createPitchShift,
  PITCH_SHIFT_COLOR,
  createMonoSynth,
  MONO_SYNTH_COLOR
} from "../molecules"
import type { ComponentType } from "react"
import type { Molecule } from "../molecules"

type Props = {
  handleClear: (e: SyntheticEvent<HTMLButtonElement>) => void,
  handleInspector: (e: SyntheticEvent<HTMLButtonElement>) => void,
  isInspectorOn: boolean,
  handleEditor: (e: SyntheticEvent<HTMLButtonElement>) => void,
  isEditorOn: boolean,
  handleDocumentation: (e: SyntheticEvent<HTMLButtonElement>) => void,
  isDocumentationOn: boolean,
  handleCanvas: (e: SyntheticEvent<HTMLButtonElement>) => void,
  isCanvasOn: boolean,
  handleAddMolecule: (moleculeHandler: () => Molecule) => void,
  handleUpdateEditorText: string => void
}

const Wrapper: ComponentType<{}> = styled.div`
  margin: 0 1em;
  min-height: 50vh;
  max-height: 50vh;
`

const Header: ComponentType<{}> = styled.h2`
  margin: 0 0.25vw 1vh 0.25vw;
`

const Divider: ComponentType<{}> = styled.hr`
  margin: 0.5vh 0.25vw 1.5vh 0.25vw;
  border: none;
`

const Controls = React.memo<Props>((props: Props) => {
  const { handleUpdateEditorText } = props
  const moleculeHandlers = {
    audioSources: [
      {
        text: "oscillator",
        color: OSCILLATOR_COLOR,
        handler: createOscillator
      },
      { text: "mono synth", color: MONO_SYNTH_COLOR, handler: createMonoSynth },
      { text: "synth", color: SYNTH_COLOR, handler: createSynth }
    ],
    modifiers: [
      {
        text: "note",
        color: NOTE_COLOR,
        handler: createNote
      },
      { text: "chord", color: CHORD_COLOR, handler: createRandomChord },
      {
        text: "octave",
        color: OCTAVE_COLOR,
        handler: createRandomOctave
      }
    ],
    effects: [
      {
        text: "ping pong delay",
        color: PING_PONG_DELAY_COLOR,
        handler: createPingPongDelay
      },
      { text: "reverb", color: REVERB_COLOR, handler: createReverb },
      { text: "chorus", color: CHORUS_COLOR, handler: createChorus },
      {
        text: "pitch shift",
        color: PITCH_SHIFT_COLOR,
        handler: createPitchShift
      }
    ],
    utility: [
      { text: "play", color: PLAY_COLOR, handler: createPlay },
      { text: "clean", color: CLEAN_COLOR, handler: createClean }
    ]
  }

  return (
    <Column>
      <Wrapper>
        <Row>
          <Dropdown
            header="Explore examples..."
            data={examples.map(example => ({
              ...example,
              onClick: () => handleUpdateEditorText(example.content)
            }))}
          />
        </Row>
        <Divider />
        <Row>
          <Header>Controls</Header>
        </Row>
        <Row>
          <Volume />
        </Row>
        <Row>
          <Column>
            <Button onClick={props.handleClear}>Clear</Button>
          </Column>
          <Column>
            <ToggleableButton
              isOn={props.isCanvasOn}
              onClick={props.handleCanvas}
            >
              animations
            </ToggleableButton>
          </Column>
          <Column>
            <ToggleableButton
              isOn={props.isEditorOn}
              onClick={props.handleEditor}
            >
              editor
            </ToggleableButton>
          </Column>
          <Column>
            <ToggleableButton
              isOn={props.isInspectorOn}
              onClick={props.handleInspector}
            >
              inspector
            </ToggleableButton>
          </Column>
          <Column>
            <ToggleableButton
              isOn={props.isDocumentationOn}
              onClick={props.handleDocumentation}
            >
              documentation
            </ToggleableButton>
          </Column>
        </Row>
        <Row>
          {Object.keys(moleculeHandlers).map((key, index) => (
            <Row key={index}>
              {moleculeHandlers[key].map((moleculeHandler, index) => (
                <Column key={index}>
                  <Button
                    color={moleculeHandler.color}
                    onClick={() =>
                      props.handleAddMolecule(moleculeHandler.handler)
                    }
                  >
                    {`Add ${moleculeHandler.text}`}
                  </Button>
                </Column>
              ))}
            </Row>
          ))}
        </Row>
      </Wrapper>
    </Column>
  )
})

export default Controls
