// @flow
import React from "react"
import styled from "styled-components"
import CodeWithReferences from "./CodeWithReferences"
import docs from "./docs"
import type { ComponentType } from "react"

const Text: ComponentType<{}> = styled.p`
  line-height: 1.5em;
`

const REFERENCES = Object.keys(docs)
  .flatMap(key => docs[key])
  .map(({ name }) => name)

const HIGHLIGHT = [
  ...REFERENCES,
  "null",
  "URL",
  "addRules",
  "ionian",
  "true",
  "false"
]

const SEPARATORS = /(\.|,)/

const findSeparator = word => word.split(SEPARATORS, 2)[1]

type Props = {
  children: string
}

const addReference = (word, index) => {
  if (HIGHLIGHT.includes(word))
    return (
      <React.Fragment key={index}>
        <span>
          <CodeWithReferences word={word} />{" "}
        </span>
      </React.Fragment>
    )
  if (HIGHLIGHT.includes(word.replace(SEPARATORS, ""))) {
    return (
      <React.Fragment key={index}>
        <span>
          <CodeWithReferences word={word.replace(SEPARATORS, "")} />
        </span>
        <span>{findSeparator(word)} </span>
      </React.Fragment>
    )
  }
  return <span key={index}>{word} </span>
}

const TextWithReferences = ({ children }: Props) => {
  const text = children.split(" ").reduce((text, word, index) => {
    const newWord = addReference(word, index)
    return [...text, newWord]
  }, [])
  return <Text>{text}</Text>
}

export default TextWithReferences
