// @flow
import React from "react"
import TextWithReferences from "./TextWithReferences"

const Performance = () => (
  <>
    <TextWithReferences>
      Since Ionian is simply a prototype, slow performance may occur as the
      solution increases and many reaction rules are added. It is possible to
      improve the performance of the system in a few ways. Unless you need it
      for debugging purposes, it is generally not recommended to show the
      inspector as its rendering updates are expensive. Furthermore, it can be
      beneficial to hide the documentation and/or the editor if you do not
      intend to write new rules or look up information. Finally, if you need to
      record the output from the system, or the performace is too poor you can
      disable the animations which makes Ionian less interesting but improves
      the performance as it avoids constant rendering updates.
    </TextWithReferences>
  </>
)

export default Performance
