// @flow
import { uuidv4 } from "../../helpers"
import { DEFAULT_CHORD, toJSON as chordToJSON } from "../chord"
import { toJSON as effectToJSON } from "../effects"
import type { Chord, JSON as ChordJSON } from "../chord"
import type { Effect, JSON as EffectJSON } from "../effects"

export const COLOR = "#FABEBE"
const SYNTH_TEXT = "🎶"
const RADIUS = 32
const DEFAULT_EFFECTS = []

export type SynthParams = {|
  id: string,
  chord: Chord,
  effects: Effect[]
|}

export type Synth = {|
  +type: "synth",
  +id: string,
  +color: string,
  +text: string,
  +radius: number,
  data: {
    chord: Chord,
    effects: Effect[]
  }
|}

export type JSON = $ReadOnly<{|
  type: "synth",
  id: string,
  data: {|
    chord: ChordJSON,
    effects: EffectJSON[]
  |}
|}>

export const createSynth = (
  { id, chord, effects }: SynthParams = {
    id: uuidv4(),
    chord: DEFAULT_CHORD,
    effects: DEFAULT_EFFECTS
  }
) => ({
  type: "synth",
  id,
  color: COLOR,
  text: SYNTH_TEXT,
  radius: RADIUS,
  data: {
    chord,
    effects
  }
})

export const toJSON: Synth => JSON = synth => {
  const {
    type,
    id,
    data: { chord, effects }
  } = synth

  return {
    type,
    id,
    data: {
      chord: chordToJSON(chord),
      effects: effects.map(effectToJSON)
    }
  }
}
