// @flow
import React, { useEffect, useState } from "react"
import Tone from "tone"
import styled from "styled-components"
import { Slider, Column, Row } from "./shared"

const Bold = styled.b`
  font-weight: bold;
`

const Volume = () => {
  const [volume, setVolume] = useState(-12)

  useEffect(() => {
    const limiter = new Tone.Limiter(volume)
    Tone.Master.volume.value = volume
    Tone.Master.chain(limiter)
  }, [volume])

  const handleVolume = (e: SyntheticInputEvent<HTMLInputElement>) => {
    e.preventDefault()
    setVolume(Number(e.currentTarget.value))
    Tone.Master.volume.value = volume
  }

  return (
    <Row>
      <Column>
        <Bold>Volume ({volume} dB)</Bold>
        <Slider
          type="range"
          min="-26"
          max="0"
          step="0.05"
          value={volume}
          onChange={handleVolume}
        />
      </Column>
    </Row>
  )
}

export default Volume
